import AuthService from '@content-ignite/core/system/AuthService'
import React from 'react'
import ThemeContext from '@content-ignite/core/system/Theme'

import { Redirect, Route, RouteComponentProps } from 'react-router-dom'

type PrivateRouteProps<T> = T & { component: any }
const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps<any>) => {
  const Auth = new AuthService()
  const SwitchRoutes = (props: RouteComponentProps<any>) => {
    if (Auth.loggedIn()) {
      const store = {
        config: rest.config,
        user: Auth.getProfile()
      }
      return (
        <ThemeContext.Provider value={store}>
          <Component {...props} />
        </ThemeContext.Provider>
      )
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />
    )
  }
  return <Route {...rest} render={SwitchRoutes} />
}

export default PrivateRoute
