const Branding = (): Promise<IConfig> => {
  const targetVersion = '2'
  const localConfig = localStorage.getItem('config')
  const version = localStorage.getItem('version')
  if (localConfig !== null && version === targetVersion) {
    return new Promise(resolve => {
      resolve(JSON.parse(localConfig))
    })
  } else {
    return new Promise(resolve => {
      const config = {
        colors: {
          gradient: 'linear-gradient(-259deg,#5E60E7 0,#9C7AF2 65%)',
          primary: '#5E60E7',
          primary_dark: '#9C7AF2'
        },
        logo: 'https://cdn.tagdeliver.com/assets/img/core-full-logo.svg',
        logo_dark:
          'https://cdn.tagdeliver.com/assets/img/core-full-logo-dark.svg',
        logo_flat: 'https://cdn.tagdeliver.com/assets/img/core-logo.svg',
        name: 'CORE',
        url: 'core.contentignite.com'
      }
      localStorage.setItem('config', JSON.stringify(config))
      localStorage.setItem('version', targetVersion)
      resolve(config)
    })
  }
}
export default Branding
