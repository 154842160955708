import Button from '@material-ui/core/Button'
import { MuiThemeProvider } from '@material-ui/core/styles'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { useTracked } from 'src/workers/State'

import React, { useEffect, useState } from 'react'
import { Theme, createMuiTheme } from '@material-ui/core/styles'
// import { IConfig } from '../definitions'

const CustomTheme: React.FC<{
  config: IConfig
  // eslint-disable-next-line complexity
}> = props => {
  const [prefersDark, setPrefersDark] = useState(prefersDarkTheme())
  const { colors } = props.config
  const { primary, primary_dark } = colors
  const [state] = useTracked()

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addListener(() => {
      setPrefersDark(prefersDarkTheme())
    })
    window.addEventListener('storage', () => {
      setPrefersDark(prefersDarkTheme())
    })
  }, [])

  const theme = createMuiTheme({
    overrides: {
      MuiFormControl: {
        root: {
          zIndex: 'unset'
        }
      }
    },
    palette: {
      background: {
        default: prefersDark ? 'rgb(58,58,58)' : 'rgb(245,245,248)'
      },
      primary: {
        main: prefersDark && primary_dark ? primary_dark : primary
      },
      type: prefersDark ? 'dark' : 'light'
    }
  })
  if (theme && theme.overrides) {
    theme.overrides.MuiSnackbar = {
      anchorOriginBottomCenter: {
        [theme.breakpoints.up('md')]: {
          left: 'calc(50% + 120px)'
        }
      },
      anchorOriginTopCenter: {
        [theme.breakpoints.up('md')]: {
          left: 'calc(50% + 120px)'
        }
      }
    }
  }

  const initiateUpdate = (registration: ServiceWorkerRegistration) => () => {
    const registrationWaiting = registration.waiting
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
      registrationWaiting.addEventListener('statechange', e => {
        if (e.target && (e.target as ServiceWorker).state === 'activated') {
          window.location.reload()
        }
      })
    }
  }

  setWLStyles(theme)
  return (
    <MuiThemeProvider theme={theme}>
      {state.reg ? (
        <SnackbarContent
          message="There is a new version available."
          action={<Button onClick={initiateUpdate(state.reg)}>Update</Button>}
        />
      ) : null}
      {props.children}
    </MuiThemeProvider>
  )
}

function setWLStyles(theme: Theme) {
  const wlStyles = document.createElement('style')
  wlStyles.innerHTML =
    `a{color:${theme.palette.primary.main}}` +
    `a:hover{color:${
      theme.palette.type === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.dark
    }}` +
    `input:-webkit-autofill,` +
    `input:-webkit-autofill:hover,` +
    `input:-webkit-autofill:focus,` +
    `input:-webkit-autofill:active {` +
    `   -webkit-box-shadow: 0 0 0 30px ${theme.palette.background.default} inset !important;` +
    `   -webkit-text-fill-color: ${theme.palette.text.primary} !important;` +
    `   border-radius: ${theme.shape.borderRadius};` +
    `}`
  document.getElementsByTagName('head')[0].appendChild(wlStyles)
}

function prefersDarkTheme() {
  const manual = localStorage.getItem('dark_mode')
  if (manual !== null) {
    return manual === '1'
  }
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return true
  }
  return false
}

export default CustomTheme
