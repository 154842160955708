import { createContainer } from 'react-tracked'

import { Reducer, useReducer } from 'react'

type State = typeof initialState
interface IGlobalState {
  reg: null | ServiceWorkerRegistration
}
interface IAction {
  type: 'reg'
  payload: ServiceWorkerRegistration
}

const initialState: IGlobalState = {
  reg: null
}

const reducer: Reducer<State, IAction> = (state, action) => {
  switch (action.type) {
    case 'reg':
      return {
        ...state,
        reg: action.payload
      }
    default:
      throw new Error('unknown action type')
  }
}

const useValue = () => useReducer(reducer, initialState)
export const { Provider, useTracked } = createContainer(useValue)
