import 'src/assets/css/index.css'

import App from 'src/App'
import Branding from 'src/workers/Branding'
import { Provider } from 'src/workers/State'
import React from 'react'
import ReactDOM from 'react-dom'

Branding()
  .then((config: IConfig) => {
    ReactDOM.render(
      <Provider>
        <App config={config} />
      </Provider>,
      document.getElementById('root') as HTMLElement
    )
  })
  .catch((err: Error) => {
    const root = document.getElementById('root') as HTMLElement
    root.innerHTML =
      `<div class="flex-wrapper"><div>` +
      `<h1>Oh no. Looks like something has gone wrong our end.</h1>` +
      `<h2>Please contact your account manager to get this issue resolved.</h2>` +
      `<p>${err.message}</p></div></div>`
  })
