import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import CustomTheme from 'src/components/CustomTheme'
import PrivateRoute from './components/PrivateRoute'
import { RouteComponentProps } from 'react-router'

import React, { Suspense, lazy } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

const Dash = lazy(() => import('./routes/Dash'))
const ForgotPassword = lazy(() => import('./routes/ForgotPassword'))
const Invoices = lazy(() => import('./routes/Invoices'))
const Login = lazy(() => import('./routes/Login'))
const NotFound = lazy(() => import('./routes/NotFound'))
const ResetPassword = lazy(() => import('./routes/ResetPassword'))
const PublisherStats = lazy(() => import('./routes/PublisherStats'))

const App: React.FC<{ config: IConfig }> = props => {
  const config = props.config
  const LoginPage = <T extends RouteComponentProps<{}>>(p: T) => (
    <Login config={config} {...p} />
  )
  const ForgotPasswordPage = <T extends RouteComponentProps<{}>>(p: T) => (
    <ForgotPassword config={config} {...p} />
  )
  const ResetPasswordPage = <T extends RouteComponentProps<{}>>(p: T) => (
    <ResetPassword config={config} {...p} />
  )
  return (
    <Router>
      <CustomTheme config={config}>
        <CssBaseline />
        <Suspense
          fallback={
            <div className="fullPage">
              <CircularProgress size={80} />
            </div>
          }
        >
          <Switch>
            <PrivateRoute
              exact={true}
              path="/"
              config={config}
              component={Dash}
            />
            <PrivateRoute
              exact={true}
              path="/invoices/:date?"
              config={config}
              component={Invoices}
            />
            <PrivateRoute
              exact={true}
              path="/publisher-stats"
              config={config}
              component={PublisherStats}
            />
            <Route
              exact={true}
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <Route
              exact={true}
              path="/reset-password"
              component={ResetPasswordPage}
            />
            <Route exact={true} path="/login" component={LoginPage} />
            <PrivateRoute config={config} component={NotFound} />
          </Switch>
        </Suspense>
      </CustomTheme>
    </Router>
  )
}

export default App
